import React, { useState } from 'react';
import './Style/Register.css';

function Register() {
  // State hooks for form fields and steps
  const [step, setStep] = useState(1);
  const [identity, setIdentity] = useState('');
  const [primarySport, setPrimarySport] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  // Function to move to next step
  const handleNext = () => {
    // Validation logic here for the first part
    if (!identity || !primarySport || !firstName || !surname) {
      setErrorMessage('Please fill in all fields.');
      return;
    }
    setStep(2);
  };
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    // Additional validation logic here for the second part
    if (!username || !email || !password || !confirmPassword) {
      setErrorMessage('Please fill in all fields.');
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    // Handle registration logic here
    console.log('Registering with:', username, email, password);

    try {
      const response = await fetch('https://server.injureid.com/api/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
          identity: identity,
          primarySport: primarySport,
          firstName: firstName,
          surname: surname
        }),
      });

      if (!response.ok) {
        throw new Error('Server responded with an error.');
      }

      const data = await response.json();
      console.log('Registration successful:', data);
      // Redirect or show success message
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registration failed. Please try again.');
    }
    // Redirect or show success message
  };

  return (
    <div className="register-container">
      <h1>Register</h1>
      {step === 1 && (
        <form onSubmit={(e) => e.preventDefault()} className="register-form">
          <div className="form-section">
            <h2>Tell us about yourself</h2>
            <label htmlFor="identity">I am:</label>
            <select id="identity" value={identity} onChange={(e) => setIdentity(e.target.value)}>
              <option value="">Select...</option>
              <option value="athlete">An Athlete</option>
              <option value="coach">A Coach</option>
              <option value="other">Other</option>
            </select>

            <label htmlFor="primarySport">My primary Sport:</label>
            <input
              type="text"
              id="primarySport"
              value={primarySport}
              onChange={(e) => setPrimarySport(e.target.value)}
            />

            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <label htmlFor="surname">Surname:</label>
            <input
              type="text"
              id="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <button type="button" onClick={handleNext} className="register-button">Next</button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleSubmit} className="register-form">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="form-section">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="confirm-password">Confirm Password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          </div>
         
          <button type="submit" className="register-button">Register</button>
        </form>
      )}
    </div>
  );
}

export default Register;
