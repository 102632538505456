import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  // Function to check the session
  const checkSession = async () => {
    try {
      const response = await axios.get('https://server.injureid.com/api/check-session', { withCredentials: true });
      if (response.status === 200) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      setIsLoggedIn(false);
    } finally {
      setLoading(false);
    }
  };

  // Check the session when the provider is mounted
  useEffect(() => {
    checkSession();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, loading, checkSession }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
