import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Style/D_ViewHistory.css';

function ViewHistory() {
  const [latestInjury, setLatestInjury] = useState(null);
  const [activeInjuries, setActiveInjuries] = useState([]);
  const [historicalInjuries, setHistoricalInjuries] = useState([]);
  const [activeStartPosition, setActiveStartPosition] = useState(0);
  const [historicalStartPosition, setHistoricalStartPosition] = useState(0);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingHistorical, setLoadingHistorical] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLatestInjury();
    loadActiveInjuries();
    loadHistoricalInjuries();
  }, []);

  const fetchLatestInjury = async () => {
    try {
      const response = await axios.get('https://server.injureid.com/api/users/latest-injury', {
        params: { status: true },
        withCredentials: true,
        credentials: 'include'
      });
      setLatestInjury(response.data);
    } catch (error) {
      console.error('Error fetching latest injury:', error);
    }
  };

  const loadActiveInjuries = async () => {
    if (loadingActive) return;
    setLoadingActive(true);
    try {
      const response = await axios.get(`https://server.injureid.com/api/users/injuries/${activeStartPosition}/10`, {
        params: { status: true },
        withCredentials: true,
        credentials: 'include'
      });
      setActiveInjuries([...activeInjuries, ...response.data]);
      setActiveStartPosition(activeStartPosition + 10);
    } catch (error) {
      console.error('Error fetching active injuries:', error);
    }
    setLoadingActive(false);
  };

  const loadHistoricalInjuries = async () => {
    if (loadingHistorical) return;
    setLoadingHistorical(true);
    try {
      const response = await axios.get(`https://server.injureid.com/api/users/injuries/${historicalStartPosition}/10`, {
        params: { status: false },
        withCredentials: true,
        credentials: 'include'
      });
      setHistoricalInjuries([...historicalInjuries, ...response.data]);
      setHistoricalStartPosition(historicalStartPosition + 10);
    } catch (error) {
      console.error('Error fetching historical injuries:', error);
    }
    setLoadingHistorical(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const bottomOfPage = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight;
      if (bottomOfPage) {
        loadActiveInjuries();
        loadHistoricalInjuries();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadingActive, loadingHistorical, activeStartPosition, historicalStartPosition]);

  function renderInjuryCard(injury, isLatest = false) {
    return (
      <div key={injury._id} className="injury-card" onClick={() => navigate('/injurydetails', { state: { data: injury.data, status:isLatest } })}>
        <p>Date of Event: {new Date(injury.data.date_of_event).toLocaleDateString()}</p>
        <p>Injury Description: {injury.data.injury_description}</p>
      </div>
    );
  }

  return (
    <div>
      {latestInjury ? (
        <div className="latest-injury-container">
          <h2>Latest Injury Details</h2>
          {renderInjuryCard(latestInjury, true)}
        </div>
      ) : (
        <p>Loading latest injury data...</p>
      )}

      <div className="active-injuries-container">
        <h2>Active Injuries</h2>
        {activeInjuries.map(injury => renderInjuryCard(injury, true))}
        {loadingActive && <p>Loading more active injuries...</p>}
      </div>

      <div className="historical-injuries-container">
        <h2>Historical Injuries</h2>
        {historicalInjuries.map(injury => renderInjuryCard(injury))}
        {loadingHistorical && <p>Loading more historical injuries...</p>}
      </div>
    </div>
  );
}

export default ViewHistory;
