import React, { useState } from 'react';
import axios from 'axios';
import './Style/D_InjuryForm.css'; // Ensure you have the CSS file imported

function InjuryForm() {
  const [selectedTypeOfInjury, setSelectedTypeOfInjury] = useState([]);
  const [selectedLocationOfInjury, setSelectedLocationOfInjury] = useState([]);

  const [formData, setFormData] = useState({
      dateOfEvent: '',
      timeOfEvent: '',
      placeOfInjury: '',
      sportPlaying: '',
      locationOnField: '',
      injuryDescription: '',
      typeOfInjury: [],
      locationOfInjury: [],
      tnExplanation: '',
      tnMedication: '',
      tnMedicalProfession: [],
      tnEst: [],
  });

  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  };

  const toggleOption = (option, setSelectedOptions) => {
      setSelectedOptions((currentSelectedOptions) =>
          currentSelectedOptions.includes(option)
              ? currentSelectedOptions.filter((selectedOption) => selectedOption !== option)
              : [...currentSelectedOptions, option]
      );
  };

  const handleSubmit = async (event) => {
      event.preventDefault();

      // Update formData with the selected options just before submitting
      const completeFormData = {
          ...formData,
          typeOfInjury: selectedTypeOfInjury,
          locationOfInjury: selectedLocationOfInjury
      };

      try {
          console.log(completeFormData); // Now this will include the selections
          const response = await axios.post('https://server.injureid.com/api/injuries', completeFormData, {
              withCredentials: true
          });
          console.log('Form submitted successfully:', response.data);

          // Reset the form and selected options
          setFormData({
              dateOfEvent: '',
              timeOfEvent: '',
              placeOfInjury: '',
              sportPlaying: '',
              locationOnField: '',
              injuryDescription: '',
              typeOfInjury: [],
              locationOfInjury: [],
              tnExplanation: '',
              tnMedication: '',
              tnMedicalProfession: [],
              tnEst: [],
          });
          setSelectedTypeOfInjury([]);
          setSelectedLocationOfInjury([]);
      } catch (error) {
          console.error('Error submitting form:', error);
      }
  };

  return (
    <div className="submit-injury">
      <h2>Submit New Injury</h2>
      <form onSubmit={handleSubmit}>
          <fieldset>
              <legend>Event Details</legend>
              <div className="form-group">
                <label htmlFor="dateOfEvent">Date of Event:</label>
                <input
                  type="date"
                  id="dateOfEvent"
                  name="dateOfEvent"
                  value={formData.dateOfEvent}
                  onChange={handleInputChange}
                />
                <input 
                  type="time" 
                  id="timeOfEvent" 
                  name="timeOfEvent" 
                  value={formData.timeOfEvent} 
                  onChange={handleInputChange} 
                />
              </div>
              <div className="form-group">
                        <label htmlFor="placeOfInjury">Place of Injury:</label>
                        <input type="text" id="placeOfInjury" name="placeOfInjury" value={formData.placeOfInjury} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sportPlaying">Sport Playing:</label>
                        <input type="text" id="sportPlaying" name="sportPlaying" value={formData.sportPlaying} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="locationOnField">Location on Field:</label>
                        <input type="text" id="locationOnField" name="locationOnField" value={formData.locationOnField} onChange={handleInputChange} />
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Injury Details</legend>
                    <div className="form-group">
                        <label htmlFor="injuryDescription">Injury Description:</label>
                        <input type="text" id="injuryDescription" name="injuryDescription" value={formData.injuryDescription} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="typeOfInjury">Type of Injury:</label>
                        <div className="options-container">
                            {['soft tissue', 'fracture', 'abrasion', 'concussion'].map((option) => (
                                <button
                                    type="button"
                                    key={option}
                                    className={`option ${selectedTypeOfInjury.includes(option) ? 'selected' : ''}`}
                                    onClick={() => toggleOption(option, setSelectedTypeOfInjury)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="locationOfInjury">Location of Injury:</label>
                        <div className="options-container">
                            {['head', 'torso', 'arms', 'legs', 'feet', 'wrist', 'shoulder', 'knee', 'ankle', 'hand'].map((option) => (
                                <button
                                    type="button"
                                    key={option}
                                    className={`option ${selectedLocationOfInjury.includes(option) ? 'selected' : ''}`}
                                    onClick={() => toggleOption(option, setSelectedLocationOfInjury)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Treatment and Notes</legend>
                    <div className="form-group">
                        <label htmlFor="tnExplanation">Explanation:</label>
                        <input type="text" id="tnExplanation" name="tnExplanation" value={formData.tnExplanation} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tnMedication">Medication:</label>
                        <input type="text" id="tnMedication" name="tnMedication" value={formData.tnMedication} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tnMedicalProfession">Medical Profession:</label>
                        <input type="text" id="tnMedicalProfession" name="tnMedicalProfession" value={formData.tnMedicalProfession} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tnEst">Estimation:</label>
                        <input type="text" id="tnEst" name="tnEst" value={formData.tnEst} onChange={handleInputChange} />
                    </div>
                </fieldset>

                <button type="submit">Submit Injury</button>
            </form>
        </div>
    )
}

export default InjuryForm;
