import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PopupForm from '../Components/GD_PopupForm';
import axios from 'axios';
import './Style/GroupDetails.css';

function GroupDetails() {
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const selectUser = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const response = await axios.get(`https://server.injureid.com/api/groups/${groupId}`,
        { withCredentials: true });
        setGroup(response.data);
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    };

    fetchGroupDetails();
  }, [groupId]);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleInvitePlayer = async () => {
    if (!validateEmail(inviteEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(`https://server.injureid.com/api/groups/${groupId}/request`, 
      { email: inviteEmail },
      { withCredentials: true }
      );
      // Clear the invite input field and error message after successful invite
      setInviteEmail("");
      setErrorMessage("");
      // Optionally, refresh group details or show a success message
    } catch (error) {
      console.error('Error sending invite:', error);
      // Handle errors, e.g., show an error message
      setErrorMessage("Error sending invite. Please try again.");
    }
  };

  return (
    <div className="group-details-container">
    <h2>Group Details</h2>
    <section>
      <h4>Invite to Group</h4>
      <div className="group-invite-container">
      <input
        type="email"
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        placeholder="Enter email to invite"
      />
      <button onClick={handleInvitePlayer}>Invite</button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
    </section>
    {group ? (
      <div>
      <h2>Group Name: {group.name}</h2>
    
      <section>
        <h3>Admins</h3>
        <div className="members-container">
          {group.admins.map((admin) => (
            <div key={admin.name} className="member-card" onClick={() => selectUser(admin.id)}>
              <img src={admin.imageUrl || "/path/to/placeholder.jpg"} alt={admin.name} className="member-image" />
              <span className="member-name">{admin.name}</span>
            </div>
          ))}
        </div>
      </section>
    
      <section>
        <h3>Trainers</h3>
        <div className="members-container">
          {group.users.filter(user => user.role === 'trainer').map((trainer) => (
            <div key={trainer.id} className="member-card" onClick={() => selectUser(trainer.id)}>
              <img src={trainer.imageUrl || "/path/to/placeholder.jpg"} alt={trainer.name} className="member-image" />
              <span className="member-name">{trainer.name}</span>
            </div>
          ))}
        </div>
      </section>
    
      <section>
        <h3>Players</h3>
        <div className="members-container">
          {group.users.filter(user => user.role === 'player').map((player) => (
            <div key={player.id} className="member-card" onClick={() => selectUser(player.id)}>
              <img src={player.imageUrl || "/path/to/placeholder.jpg"} alt={player.name} className="member-image" />
              <span className="member-name">{player.name}</span>
            </div>
          ))}
        </div>
        {group.isAdmin && (
          <button onClick={handleInvitePlayer}>Invite Player</button>
        )}
      </section>
    
      <section>
        <h3>New Comers</h3>
        <div className="members-container">
          {group.users.filter(user => user.role === 'new').map((newcomer) => (
            <div key={newcomer.id} className="member-card" onClick={() => selectUser(newcomer.id)}>
              <img src={newcomer.imageUrl || "/path/to/placeholder.jpg"} alt={newcomer.name} className="member-image" />
              <span className="member-name">{newcomer.name}</span>
            </div>
          ))}
        </div>
      </section>

      <PopupForm isOpen={isPopupOpen} onClose={closePopup} userDetails={selectedUser} groupId={groupId} />
    </div>
    ) : (
      <p>Loading group details...</p>
    )}
  </div>
    );
  }

export default GroupDetails;
