import React from 'react';
import './Style/LandingPage.css';
import Header from '../Components/Header';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="hero-section">
        <h1>Welcome to InjureID</h1>
        <p>Your personal medical history tracker at your fingertips.</p>
        <a href="/register" className="cta-button">Get Started</a>
      </div>
      <div className="features-section">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature">
            <h3>Track Your Medical History</h3>
            <p>Keep a detailed record of your medical history for easy access and management.</p>
          </div>
          <div className="feature">
            <h3>Secure & Private</h3>
            <p>Your data is encrypted and stored securely, ensuring your privacy is always protected.</p>
          </div>
          <div className="feature">
            <h3>Easy to Use</h3>
            <p>Our intuitive interface makes tracking your health information effortless.</p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default LandingPage;
