import React from 'react';
import { useLocation } from 'react-router-dom';
import './Style/InjuryDetails.css';

function InjuryDetails() {
  const location = useLocation();
  const { data, status } = location.state;

  return (
    <div className="injury-details-container">
        <h2>{status ? 'Active Injury Details' : 'Historical Injury Details'}</h2>
        <section>
            <h3>Event Information</h3>
            <div className="detail">
                <strong>Date of Event:</strong>
                <span>{new Date(data.date_of_event).toLocaleDateString()}</span>
            </div>
            <div className="detail">
                <strong>Place of Injury:</strong>
                <span>{data.place_of_injury}</span>
            </div>
            <div className="detail">
                <strong>Sport Playing:</strong>
                <span>{data.sport_playing}</span>
            </div>
            <div className="detail">
                <strong>Location on Field:</strong>
                <span>{data.location_on_field}</span>
            </div>
        </section>
        <section>
            <h3>Injury and Treatment</h3>
            <div className="detail">
                <strong>Injury Description:</strong>
                <span>{data.injury_description}</span>
            </div>
            <div className="detail">
                <strong>Type of Injury:</strong>
                <span>{data.type_of_injury.join(', ')}</span>
            </div>
            <div className="detail">
                <strong>Location of Injury:</strong>
                <span>{data.location_of_injury.join(', ')}</span>
            </div>
        </section>
        <section>
            <h3>Treatment Notes</h3>
            <div className="detail">
                <strong>Explanation:</strong>
                <span>{data.tn_explanation}</span>
            </div>
            <div className="detail">
                <strong>Medication:</strong>
                <span>{data.tn_medication}</span>
            </div>
            <div className="detail">
                <strong>Medical Profession:</strong>
                <span>{data.tn_medical_profession.join(', ')}</span>
            </div>
            <div className="detail">
                <strong>Estimation:</strong>
                <span>{data.tn_est.join(', ')}</span>
            </div>
        </section>
    </div>
  );
}

export default InjuryDetails;
