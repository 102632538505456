import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './Pages/LandingPage';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Dashboard from './Pages/Dashboard';
import InjuryDetails from './Pages/InjuryDetails';
import GroupDetails from './Pages/GroupDetails';
import Header from './Components/Header';
import { AuthContext } from './Context/auth-context';

function App() {
  const { isLoggedIn, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or similar
  }

  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Dashboard /> : <LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/injurydetails" element={<InjuryDetails />} />
          <Route path="/groupdetails/:groupId" element={<GroupDetails/>}/>
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
