import React, { useState, useEffect } from 'react';
import './Style/Dashboard.css';
import { useNavigate } from 'react-router-dom';
import ViewHistory from '../Components/D_ViewHistory';
import InjuryForm from '../Components/D_InjuryForm';
import ManageProfile from '../Components/D_ManageProfile';
import axios from 'axios';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('profile');
  const [activeProfileMenu, setActiveProfileMenu] = useState('viewHistory');
  const [activeGroupMenu, setActiveGroupMenu] = useState('myGroups');
  const [groupName, setGroupName] = useState('');
  const [myGroups, setMyGroups] = useState([]);
  const [joinedGroups, setJoinedGroups] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const memberGroupsResponse = await axios.get('https://server.injureid.com/api/users/member-groups', {
          withCredentials: true,
          credentials: 'include'
        });
        const adminGroupsResponse = await axios.get('https://server.injureid.com/api/users/admin-groups', {
          withCredentials: true,
          credentials: 'include'
        });
        setMyGroups(adminGroupsResponse.data);
        setJoinedGroups(memberGroupsResponse.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    fetchGroups();
  }, []);

  const handleAddGroup = async (event) => {
    event.preventDefault();
    if (groupName.trim() !== '') {
      try {
        const response = await axios.post('https://server.injureid.com/api/groups/create', {
          name: groupName
        }, {
          withCredentials: true
        });
        const newGroup = response.data;
        setMyGroups([...myGroups, newGroup]);
        setGroupName('');
      } catch (error) {
        console.error('Error adding group:', error);
      }
    }
  };

  const handleGroupCardClick = (groupId) => {
    navigate(`/groupdetails/${groupId}`); // Navigate to the group detail page with the group ID
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <h1>Welcome to Your Dashboard</h1>
        <p>This is a secure area of the app. Here you can view your medical history, manage your profile, and more.</p>
        <div className="tabs">
          <button className={`tab ${activeTab === 'profile' ? 'active' : ''}`} onClick={() => setActiveTab('profile')}>Manage Profile</button>
          <button className={`tab ${activeTab === 'group' ? 'active' : ''}`} onClick={() => setActiveTab('group')}>Manage Group</button>
        </div>
        <div className="tab-content">
          {activeTab === 'profile' && (
            <div>
              <h2>Manage Profile</h2>
              {/* Profile management content */}
            </div>
          )}
          {activeTab === 'group' && (
            <div>
              <h2>Manage Group</h2>
              {/* Group management content */}
            </div>
          )}
        </div>
        <div className="tab-content">
          {activeTab === 'profile' && (
            <div className="profile-content">
              <div className="sidebar">
                <button className={`menu-item ${activeProfileMenu === 'viewHistory' ? 'active' : ''}`} onClick={() => setActiveProfileMenu('viewHistory')}>View Medical History</button>
                <button className={`menu-item ${activeProfileMenu === 'manageinjury' ? 'active' : ''}`} onClick={() => setActiveProfileMenu('manageinjury')}>Submit New Injury</button>
                <button className={`menu-item ${activeProfileMenu === 'manageProfile' ? 'active' : ''}`} onClick={() => setActiveProfileMenu('manageProfile')}>Manage Profile</button>
              </div>
              <div className="profile-details">
                {activeProfileMenu === 'viewHistory' && <ViewHistory/>}
                {activeProfileMenu === 'manageinjury' && <InjuryForm/>}
                {activeProfileMenu === 'manageProfile' && <ManageProfile/>}
                </div>
              </div>
            )}
            {activeTab === 'group' && (
              <div className="group-content">
                <div className="sidebar">
                  <button
                    className={`menu-item ${activeGroupMenu === 'myGroups' ? 'active' : ''}`}
                    onClick={() => setActiveGroupMenu('myGroups')}
                  >
                    My Groups
                  </button>
                  <button
                    className={`menu-item ${activeGroupMenu === 'joinedGroups' ? 'active' : ''}`}
                    onClick={() => setActiveGroupMenu('joinedGroups')}
                  >
                    Joined Groups
                  </button>
                </div>
                <div className="group-details">
                  {/* Content based on activeGroupMenu */}
                  {activeGroupMenu === 'myGroups' && (
                    <div>
                      <h2>My Groups</h2>
                      <form onSubmit={handleAddGroup} className="group-form">
                        <input
                          type="text"
                          placeholder="Enter group name"
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                        <button type="submit">Add Group</button>
                      </form>
                      <div className="group-cards">
                        {myGroups.map((group) => (
                          <div key={group._id} className="group-card" onClick={() => handleGroupCardClick(group._id)}>
                            <img src="path/to/your/image.png" alt="" className="group-image" />
                            <div className="group-name">{group.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeGroupMenu === 'joinedGroups' && (
                    <div>
                      <h2>Joined Groups</h2>
                      <div className="group-cards">
                        {joinedGroups.map((group) => (
                          <div key={group._id} className="group-card" onClick={() => handleGroupCardClick(group._id)}>
                            <img src="path/to/your/image.png" alt="" className="group-image" />
                            <div className="group-name">{group.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
  );
}


export default Dashboard;
