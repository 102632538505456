import React, { useState, useEffect, useContext } from 'react';
import './Style/Header.css';
import axios from 'axios';
import { AuthContext } from '../Context/auth-context';
import { FaBell, FaCaretDown } from 'react-icons/fa'; // Import Font Awesome Icons

function Header() {
  const { isLoggedIn } = useContext(AuthContext);
  const [teamInvites, setTeamInvites] = useState([]);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showTeamInvitesDropdown, setShowTeamInvitesDropdown] = useState(false);

  useEffect(() => {
    const fetchTeamInvites = async () => {
      try {
        const response = await axios.get('https://server.injureid.com/api/users/join-requests', { withCredentials: true });
        setTeamInvites(response.data);
      } catch (error) {
        console.error('Error fetching team invites:', error);
      }
    };

    if (isLoggedIn) {
      fetchTeamInvites();
    }
  }, [isLoggedIn]);

  const handleLogout = async () => {
    try {
      await axios.post('https://server.injureid.com/api/users/logout', {}, { withCredentials: true });
      // Redirect to the homepage or login page after successful logout
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleAcceptInvite = async (groupId) => {
    try {
      const response = await axios.post(
        `https://server.injureid.com/api/users/join-request/groups/${groupId}`, 
        {action:"accept"},
        { withCredentials: true }
      );
      console.log('Invite accepted:', response.data);
      // Update the UI or state as needed
    } catch (error) {
      console.error('Error accepting invite:', error);
    }
  };
  
  const handleRejectInvite = async (groupId) => {
    try {
      const response = await axios.post(
        `https://server.injureid.com/api/users/join-request/groups/${groupId}`,
        {action:"reject"},
        { withCredentials: true }
      );
      console.log('Invite rejected:', response.data);
      // Update the UI or state as needed
    } catch (error) {
      console.error('Error rejecting invite:', error);
    }
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <h1 className="app-title">InjureID</h1>
        <nav className="header-nav">
          {isLoggedIn ? (
            <>
              <div className="profile-dropdown"
                onMouseEnter={() => setShowProfileDropdown(true)}
                onMouseLeave={() => setShowProfileDropdown(false)}>
                <a href="/profile" className="header-link">Profile <FaCaretDown /></a>
                {showProfileDropdown && (
                  <div className="profile-dropdown-content">
                    <a href="/manage-profile">Manage My Profile</a>
                    {/* Team Invites Dropdown */}
                    <div className="team-invites-dropdown"
                        onMouseEnter={() => setShowTeamInvitesDropdown(true)}
                        onMouseLeave={() => setShowTeamInvitesDropdown(false)}>
                      <span>Team Invites</span> {teamInvites.length > 0 && <FaBell />}
                      {(showTeamInvitesDropdown || teamInvites.length > 0) && (
                        <div className="team-invites-dropdown-content">
                          {teamInvites.map((invite) => (
                            <div key={invite._id} className="team-invite">
                              <span>{invite.name}</span>
                              <div>
                                <button onClick={() => handleAcceptInvite(invite.id)}>Accept</button>
                                <button onClick={() => handleRejectInvite(invite.id)}>Reject</button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
            <button onClick={handleLogout} className="header-button">Logout</button>
            </>
          ) : (
            <>
              <a href="/login" className="header-link">Login</a>
              <a href="/register" className="header-link">Register</a>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}
export default Header;
