import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Style/GD_PopupForm.css';

function PopupForm({ isOpen, onClose, userDetails , groupId}){
  const [activeTab, setActiveTab] = useState('roleManagement');
  const [showRehabPopup, setShowRehabPopup] = useState(false);
  const [selectedInjuryId, setSelectedInjuryId] = useState(null);
  

  if (!isOpen) {
    return null;
  }

  const closeRehabPopup = () => {
    setShowRehabPopup(false); // Close the popup
  };

  return <>
    <div className={`popup-form-backdrop ${isOpen ? 'active' : ''}`}>
        <div className={`popup-form ${isOpen ? 'active' : ''}`}>
          <div className="popup-form-header">
            <button
              onClick={() => setActiveTab('roleManagement')}
              className={activeTab === 'roleManagement' ? 'active' : ''}
            >
              User Role Management
            </button>
            <button
              onClick={() => setActiveTab('viewInjury')}
              className={activeTab === 'viewInjury' ? 'active' : ''}
            >
              View Active Injuries
            </button>
            <button onClick={onClose} className="close-btn"/>
          </div>
          <div className="popup-form-content">
            {activeTab === 'roleManagement' && <UserRoleManagement userDetails={userDetails} groupId={groupId} />}
            {activeTab === 'viewInjury' && <ViewInjury userDetails={userDetails} setShowRehabPopup={setShowRehabPopup} setSelectedInjuryId={setSelectedInjuryId} />}
          </div>
        </div>
      </div>
    {/* Rehabilitation Popup */}
    {showRehabPopup && (
        <RehabilitationPopup
          injuryId={selectedInjuryId}
          groupId={groupId}
          onClose={closeRehabPopup}
        />
      )}
  </>;
}

function UserRoleManagement({ userDetails, groupId }) {
  const [newRole, setNewRole] = useState('');

  const handleRoleChange = (e) => {
    setNewRole(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://server.injureid.com/api/groups/change-role', {
        groupId: groupId,
        userId: userDetails.id,
        newRole: newRole
      }, {
        withCredentials: true
      })
      
      // Handle response
      alert('Role updated successfully!');
    } catch (error) {
      console.error('Error updating role:', error);
      alert('Failed to update role.');
    }
  };

  return (
    <div className="change-role-container">
      <h2>Change User Role</h2>
      <form className="change-role-form" onSubmit={handleSubmit}>
        <label htmlFor="role-select">Choose a new role:</label>
        <select id="role-select" value={newRole} onChange={handleRoleChange}>
          <option value="">Select a role</option>
          <option value="admin">Admin</option>
          <option value="trainer">Trainer</option>
          <option value="player">Player</option>
        </select>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

function ViewInjury({ userDetails, setShowRehabPopup, setSelectedInjuryId}) {
  const [activeInjuries, setActiveInjuries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInjuries = async () => {
      try {
        const response = await axios.get(`https://server.injureid.com/api/users/injuries/${userDetails}`, {
          withCredentials: true,
        });
        setActiveInjuries(response.data); // Assuming the data is an array of injuries
      } catch (error) {
        console.error('Error fetching injuries:', error);
      }
    };

    fetchInjuries();
  }, [userDetails]);

  const handleAddRehabilitation = (injuryId) => {
    setSelectedInjuryId(injuryId);
    setShowRehabPopup(true); // Open the popup
  };

  const handleInjuryHealed = (injuryId) => {
    // Implement the functionality to mark injury as healed
  };

  const viewPastInjuries = () => {
    navigate('/injury');
  };

  return (
    <div>
      {activeInjuries.map((injury) => (
        <div key={injury.data._id} className="injury-card">
          <h3>Injury Details</h3>
          <p>Date: {new Date(injury.data.date_of_event).toLocaleDateString()}</p>
          <p>Type: {injury.data.type_of_injury.join(', ')}</p>
          <p>Location: {injury.data.location_of_injury.join(', ')}</p>
          <div className="button-container">
            <button onClick={() => handleAddRehabilitation(injury._id)} className="injury-button">Add Rehabilitation</button>
            <button onClick={() => handleInjuryHealed(injury._id)} className="injury-button">Injury Healed</button>
        </div>
        </div>
      ))}
      <button onClick={viewPastInjuries}>View Past Injuries</button>

    </div>
  );
}

function RehabilitationPopup({ injuryId, groupId, onClose }) {
  const [rehabilitationNotes, setRehabilitationNotes] = useState([]);
  const [noteText, setNoteText] = useState('');

  useEffect(() => {
    const fetchRehabilitationNotes = async () => {
      try {
        const response = await axios.get(`https://server.injureid.com/api/rehabilitation/${injuryId}`,{withCredentials:true});
        setRehabilitationNotes(response.data);
      } catch (error) {
        console.error('Error fetching rehabilitation notes:', error);
      }
    };

    fetchRehabilitationNotes();
  }, [injuryId]);

  const handleNoteSubmit = async () => {
    try {
      const response = await axios.post(`https://server.injureid.com/api/rehabilitation/`,
      {
        injuryId: injuryId,
        groupId: groupId,
        planDetails: noteText
      },{
        withCredentials:true
      });
      setRehabilitationNotes(prevNotes => [
        ...prevNotes,
        response.data.rehabilitation
      ]);
  
      // Clear the input field
      setNoteText('');


    } catch (error) {
      console.error('Error fetching rehabilitation notes:', error);
    }
  };

  return (
    <div className="rehabilitation-popup-backdrop">
      <div className="rehabilitation-popup">
      <div className="popup-header">
        <h3>Rehabilitation Notes</h3>
        <button onClick={onClose} className="close-btn"/>
      </div>
        <div className="notes-container">
          <div className="trainer-notes">
            <h3>Trainer's Notes</h3>
            {rehabilitationNotes.filter(note => note.role === 'trainer').map((note, index) => (
              <div key={index} className="note-card">
                <p>{note.rehabilitation}</p>
              </div>
            ))}
          </div>
          <div className="player-notes">
            <h3>Player's Notes</h3>
            {rehabilitationNotes.filter(note => note.role === 'player').map((note, index) => (
              <div key={index} className="note-card">
                <p>{note.rehabilitation}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="add-note-container">
          <input
            type="text"
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
            placeholder="Enter your rehabilitation note"
          />
          <button onClick={handleNoteSubmit}>Submit Note</button>
        </div>
        
      </div>
    </div>
  );
}



export default PopupForm;
