// ManageProfile.js
import React, {useEffect, useState } from 'react';
import './Style/D_ManageProfile.css';
import axios from 'axios';
// You may need to import additional components or CSS for styling.

function ManageProfile() {
  // State for profile data
  const [profileData, setProfileData] = useState({
    username: '',
    email: '',
    password: '',
    primarySport: '',
    firstName: '',
    surname: '',
    height: '',
    weight: '',
    dateOfBirth: '',
    clubName: '',
    dateJoinedClub: '',
    position: '',
  });

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get('https://server.injureid.com/api/users/', {
          withCredentials: true,
          credentials: 'include'
        });
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    fetchGroups();
  }, []);

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData({ ...profileData, [name]: value });
  };

  // Function to submit the profile data
  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await axios.put('https://server.injureid.com/api/users/update', profileData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          // Include other headers like Authorization if needed
        },
      });
      console.log('Profile update successful:', response.data);
      // Handle the response, maybe set a success message or redirect
    } catch (error) {
      console.error('Profile update failed:', error);
      // Handle the error, maybe set an error message
    }
  };

  // Render the profile form
  return (
    <div className="manage-profile-container">
      <h2>Manage Your Profile</h2>
      <form onSubmit={handleProfileSubmit} className="manage-profile-form">
        {/* Form fields */}
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={profileData.username}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={profileData.email}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="primarySport"
          placeholder="Primary Sport"
          value={profileData.primarySport}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={profileData.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="surname"
          placeholder="Surname"
          value={profileData.surname}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="height"
          placeholder="Height"
          value={profileData.height}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="weight"
          placeholder="Weight"
          value={profileData.weight}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="dateOfBirth"
          placeholder="Date of Birth"
          value={profileData.dateOfBirth}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="clubName"
          placeholder="Club Name"
          value={profileData.clubName}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="dateJoinedClub"
          placeholder="Date Joined Club"
          value={profileData.dateJoinedClub}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="position"
          placeholder="Position in Club/Sport"
          value={profileData.position}
          onChange={handleInputChange}
        />
        <button type="submit" className="profile-submit-button">
          Update Profile
        </button>
      </form>
    </div>
  );
}

export default ManageProfile;
